import React from "react";

import "./input.scss";

interface InputProps {
	classes?: string;
	value?: string;
	error?: string;
	onChange?: (event) => void;
}

export class Input extends React.Component<InputProps> {
	render() {
		let classNames = [ "input" ].concat(this.props.classes || []);
		if (this.props.error)
			classNames.push("is-error");

		return (
			<div className={classNames.join(" ")}>
				<input className="input-control" type="text" value={this.props.value} onChange={this.props.onChange}/>
				{this.props.error ?
						<div className="error">
							{this.props.error}
						</div>
					:
						null
				}
			</div>
		);
	}
}