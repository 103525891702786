import React from "react";

import { SubscriptionContainer } from "../containers/subscription";

export class Subscription extends React.Component {
	render() {
		return (
			<SubscriptionContainer/>
		);
	}
}