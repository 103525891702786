let mixpanelBrowser = require("mixpanel-browser");
let mixpanel: any = null;

export function init() {
	mixpanel = mixpanelBrowser.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
		autotrack: false,
	}, "mixpanel");
}

export function alias(distinctId: string) {
	if (!mixpanel)
		return;

	mixpanel.alias(distinctId);
	let deviceDistinctId = mixpanel.get_distinct_id();
	mixpanel.identify(deviceDistinctId);
}

export function identify(distinctId: string) {
	if (!mixpanel)
		return;

	mixpanel.identify(distinctId);
}

export function track(eventName: string, properties?: any) {
	if (!mixpanel)
		return;

	if (properties !== undefined)
		mixpanel.track(eventName, properties);
	else
		mixpanel.track(eventName);
}

export function setUserProperty(properties: any) {
	if (!mixpanel)
		return;

	mixpanel.people.set(properties);
}

export function reset() {
	if (!mixpanel)
		return;

	mixpanel.reset();
}