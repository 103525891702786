function trackPixelEvent(event: string) {
	let fbq = (window as any).fbq;
	if (!fbq)
		return;
	fbq("track", event);
}

export function identify(email: string) {
	let fbq = (window as any).fbq;
	if (fbq) {
		fbq("init", process.env.REACT_APP_FACEBOOK_PIXEL_ID, {
			em: email,
		});
	}
}

export function trackFacebookSignup() {
	trackPixelEvent("CompleteRegistration");

	let FB = (window as any).FB;
	if (!FB)
		return;

	let properties = {};
	properties[FB.AppEvents.ParameterNames.REGISTRATION_METHOD] = "Facebook";
	FB.AppEvents.logEvent(FB.AppEvents.EventNames.COMPLETED_REGISTRATION, null, properties);
}