import React from "react";
import { Query, QueryResult } from "react-apollo";

import { billingDetailsQuery } from "../graphql/billing";
import { BillingDetailsInfo } from "../components/billingdetails";

export function UpdateBillingDetailsContainer(WrappedComponent) {
	return class extends React.Component<any> {
		render() {
			return (
				<Query
					query={billingDetailsQuery}
					fetchPolicy="network-only"
				>
				{(result: QueryResult) => {
					if (result.loading)
						return null; // FIXME: Loading spinner.
	
					const previousValues = result.data && result.data.billingDetails ? result.data.billingDetails : null;
	
					let billingDetails: BillingDetailsInfo = {
						cardHolderName: previousValues && previousValues.cardHolderName ? previousValues.name : "",
						businessName: previousValues && previousValues.businessName ? previousValues.businessName : "",
						addressLine1: previousValues && previousValues.addressLine1 ? previousValues.addressLine1 : "",
						addressLine2: previousValues && previousValues.addressLine2 ? previousValues.addressLine2 : "",
						city: previousValues && previousValues.city ? previousValues.city : "",
						state: previousValues && previousValues.state ? previousValues.state : "",
						postalCode: previousValues && previousValues.postalCode ? previousValues.postalCode : "",
						country: previousValues ? previousValues.country : "",
						taxId: previousValues && previousValues.taxId ? previousValues.taxId : "",
					};
	
					return <WrappedComponent billingDetails={billingDetails} {...this.props}/>;
				}}
				</Query>
			);
		}
	}
}