import React from "react";

import "./toggle.scss";

interface ToggleProps {
	on: boolean;
	onToggle: () => void;
}

export class Toggle extends React.Component<ToggleProps> {
	render() {
		let className = [ "toggle" ];
		if (this.props.on)
			className.push("is-on");

		return (
			<div className={className.join(" ")} onClick={this.props.onToggle}>
				<div className="notch"></div>
			</div>
		)
	}
}