import React from "react";

import "./button.scss";

interface ButtonProps {
	classes?: string;
	text: string;
	compact?: boolean;
	confirm?: boolean;
	disabled?: boolean;
	loading?: boolean;
	link?: boolean;
	onClick: () => void;
}

export class Button extends React.Component<ButtonProps> {
	render() {
		let classNames = [ "button" ].concat(this.props.classes || []);
		if (this.props.disabled)
			classNames.push("is-disabled");
		if (this.props.confirm)
			classNames.push("is-confirm");
		if (this.props.compact)
			classNames.push("is-compact");
		if (this.props.link)
			classNames.push("is-link");

		return (
			<button className={classNames.join(" ")} onClick={this.props.loading || this.props.disabled ? () => {} : () => this.props.onClick()}>
				{ this.props.loading ? 
						"Please wait..." // FIXME: Loading spinner
					:
						this.props.text
				}
			</button>
		);
	}
}