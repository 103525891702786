import gql from "graphql-tag";

export const adAccountsQuery = gql`query FacebookAccounts {
	facebookAccounts {
		lastSyncedAt
		accounts {
			_id
			name
			currency
			billableSpend
			connectedToUser
		}
	}
}`;

export const updateAdAccountConnectionMutation = gql`
	mutation UpdateFacebookAdAccountConnection($adAccountId: String!, $connect: Boolean!) {
		updateFacebookAdAccountConnection(adAccountId: $adAccountId, connect: $connect) {
			_id
			name
			currency
			billableSpend
			connectedToUser
		}
	}
`;

export const activeUserInfoQuery = gql`query LoggedInUser {
	loggedInUser {
		name
		email
		trialExpires
	}
}`;