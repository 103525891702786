import React from "react";

import { Login } from "./screens/login";
import { Account } from "./screens/account";
import { Subscription } from "./screens/subscription";

interface AppRouterProps {
	route: string;
	navigate: (newRoute: string) => void;
}

export class AppRouter extends React.Component<AppRouterProps> {
	render() {
		switch (this.props.route) {
			case "account":
				return <Account/>;
			case "subscription":
				return <Subscription/>;
			default:
				return <Login navigate={this.props.navigate}/>;
		}
	}
}