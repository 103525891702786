import gql from "graphql-tag";

export const updateBillingDetailsMutation = gql`
	mutation updateBillingDetails($details: BillingDetailsInput!) {
		updateBillingDetails(details: $details) {
			billingEmail
			name
			addressLine1
			addressLine2
			city
			state
			country
			postalCode
			businessName
			taxId
		}
	}
`;

export const billingDetailsQuery = gql`
	query BillingDetails {
		billingDetails {
			billingEmail
			name
			addressLine1
			addressLine2
			city
			state
			country
			postalCode
			businessName
			taxId			
		}
	}
`;

export const updateBillingTokenMutation = gql`
	mutation updateBillingTokenMutation($token: String!) {
		updateBillingToken(token: $token)
	}
`;

export const startSubscriptionMutation = gql`
	mutation startSubscriptionMutation($token: String) {
		startSubscription(token: $token) {
			success
			paymentIntentSecret
			error
		}
	}
`;

export const cancelSubscriptionMutation = gql`
	mutation cancelSubscriptionMutation {
		cancelSubscription
	}
`;

export const previewNextInvoiceQuery = gql`
	query PreviewNextInvoiceQuery {
		previewNextInvoice {
			subTotal
			tax
			taxPercent
			total
			perAccount {
				_id
				name
				currency
				originalCurrency
				USD
			}
		}
	}
`;

export const billingSubscriptionQuery = gql`
	query BillingSubscriptionQuery {
		billingSubscription {
			startedAt
			nextRenewalAt
			status
			cardBrand
			cardExpiry
			card4Digits
		}
	}
`;

export const billingInvoicesQuery = gql`
	query BillingInvoicesQuery {
		billingInvoices {
			createdAt
			downloadLinkPDF
			stripeInvoiceNumber
			total
		}
	}
`;