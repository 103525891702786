import React from "react";
import Modal from "react-modal";
import { ApolloClient } from "apollo-client";
import { InMemoryCache, NormalizedCacheObject } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { ApolloLink } from "apollo-link";
import { ApolloProvider } from "react-apollo";
import { setContext } from "apollo-link-context";

import { AppRouter } from "./router";
import { AppHeader } from "./components/appheader";
import * as Mixpanel from "./mixpanel";

Modal.setAppElement("#root");

interface AppState {
	apolloClient: ApolloClient<NormalizedCacheObject> | null,
	loadedCache: boolean;
	route: string;
}

class App extends React.Component<{}, AppState> {
	state = {
		apolloClient: null,
		loadedCache: false,
		route: "login",
	};

	constructor(props) {
		super(props);
		this.navigate = this.navigate.bind(this);
	}

	async componentDidMount() {
		const cache = new InMemoryCache({
			addTypename: true,
		});

		const httpLink = new HttpLink({
			uri: process.env.REACT_APP_GRAPHQL_URI,
			credentials: "same-origin",
		});

		// FIXME: Persisted queries.

		const authLink = setContext(async (request, { headers }) => {
			let token = localStorage.getItem("userToken");

			return {
				...headers,
				headers: {
					authorization: token ? `Bearer ${token}` : null,
				},
			};
		});

		const apolloClient = new ApolloClient({
			link: ApolloLink.from([authLink, httpLink]),
			cache: cache,
			resolvers: {},
		});

		// FIXME: Write cache defaults
		// FIXME: onResetStore
		// FIXME: Persist cache
		// FIXME: Reset cache if breaking change

		const userToken = localStorage.getItem("userToken");

		try {
			Mixpanel.init();
			if (userToken) {
				const trackingId = localStorage.getItem("trackingId");
				if (trackingId)
					Mixpanel.identify(trackingId);
			}
		} catch (error) {
			console.log(error);
		}

		this.setState({
			apolloClient,
			loadedCache: true,
			route: !!userToken ? "account" : "login",
		});
	}

	navigate(newRoute: string): void {
		this.setState({
			route: newRoute,
		});
	}

	render() {
		if (!this.state.loadedCache)
			return null; // FIXME: Loading spinner

		return (
			<ApolloProvider client={(this.state.apolloClient as any)}>
				<div className="app">
					<AppHeader route={this.state.route} navigate={this.navigate}/>
					<div className="contents">
						<div className="router">
							<AppRouter route={this.state.route} navigate={this.navigate}/>
						</div>
					</div>
				</div>
			</ApolloProvider>
		);
	}
}

export default App;
