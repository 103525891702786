import React from "react";

import "./connectedaccounts.scss";
import { ConnectedAccount } from "./connectedaccount";

export interface FacebookAdAccount {
	_id: string;
	name: string;
	currency: string;
	billableSpend: number;
	connectedToUser: boolean;
}

interface ConnectedAccountsProps {
	accounts: FacebookAdAccount[];
}

export class ConnectedAccounts extends React.Component<ConnectedAccountsProps> {
	render() {
		return (
			<div className="connectedaccounts">
				<h1>Connected ad accounts</h1>
				<div className="section">
					Please note that the Facebook ad accounts listed below are contributing to your Noora subscription invoice. By turning a Facebook ad account off, it will no longer contribute to your next Noora invoice.
				</div>
				<div>
					{this.props.accounts.map(account => {
						return <ConnectedAccount key={account._id} {...account}/>
					})}
				</div>
			</div>
		);
	}
}