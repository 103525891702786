import React from "react";

import { AccountContainer } from "../containers/account";

export class Account extends React.Component {
	render() {
		return (
			<AccountContainer/>
		);
	}
}