import React from "react";

import "./form.scss";

interface FormEntryProps {
	label?: string;
	optional?: boolean;
	noFlex?: boolean; // FIXME: Stripe doesn't show if you don't have this.
	short?: boolean;
}

export class FormEntry extends React.Component<FormEntryProps> {
	render() {
		const parentClasses = [ "formentry" ];
		if (this.props.short)
			parentClasses.push("is-short");

		const childControlClasses = [ "control" ];
		if (this.props.noFlex)
			childControlClasses.push("is-noflex");

		return (
			<div className={parentClasses.join(" ")}>
				<div className="label">
				{this.props.label}
				{this.props.optional ?
						<span className="optional"> (optional)</span>
					:
						null
				}
				</div>
				<div className={childControlClasses.join(" ")}>
					{this.props.children}
				</div>
			</div>
		);
	}
}