import React from "react"

import "./tabs.scss";

interface Tab {
	id: string;
	label: string;
}

interface TabBarProps {
	onChange: (newTabId: string) => void;
	activeTabId: string;
	tabs: Tab[];
}

export class TabBar extends React.Component<TabBarProps> {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
	}

	onChange(newTabId: string) {
		this.props.onChange(newTabId);
	}

	render() {
		return (
			<div className="tabbar">
				{this.props.tabs.map(tab => {
					let classes = [ "tab", `js-tab-${tab.id}` ];

					if (tab.id === this.props.activeTabId)
						classes.push("is-active");

					return (
						<button key={tab.id} className={classes.join(" ")} onClick={() => {
							this.onChange(tab.id);
						}}>
							{tab.label}
						</button>
					);
				})}
			</div>
		);
	}
}