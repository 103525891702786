import React from "react";
import querystring from "querystring";
import { graphql, MutateProps } from "react-apollo";
import gql from "graphql-tag";
import { ChildProps, ChildMutateProps } from "react-apollo";

import "./facebooklogin.scss";
import { ReactComponent as FacebookLogo } from "../stylesheet/assets/facebook.svg";
import * as Mixpanel from "../mixpanel";
import * as Facebook from "../thirdparty/facebook";
import { ApolloClient } from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";

const appId = process.env.REACT_APP_FACEBOOK_APP_ID;
const redirectURI = `${process.env.REACT_APP_URL}/facebook_oauth`;

interface FacebookLoginProps {
	client: ApolloClient<NormalizedCacheObject>;
	navigate: (newRoute: string) => void;
}

class FacebookLoginImpl extends React.Component<ChildProps<ChildMutateProps<FacebookLoginProps, MutateProps>>> {
	code = "";

	constructor(props) {
		super(props);
		this.loginWithFacebook = this.loginWithFacebook.bind(this);

		if (document.location.pathname === "/facebook_oauth")
			this.code = (querystring.parse(document.location.search)["?code"] as string);
	}

	componentDidMount() {
		if (!this.code)
			return;
		
		let client = this.props.client;
		const navigate = this.props.navigate;

		window.history.replaceState({}, document.title, "/");

		this.props.mutate({
			variables: { facebookCode: this.code },
		}).then(function(response: any) {
			if (response.data && response.data.facebookIdentifyWithCode) {
				localStorage.setItem("userToken", response.data.facebookIdentifyWithCode.accessToken);
				localStorage.setItem("trackingId", response.data.facebookIdentifyWithCode.trackingId);

				let newSignup = !response.data.facebookIdentifyWithCode.trackingInit;
				if (newSignup) {
					Mixpanel.alias(response.data.facebookIdentifyWithCode.trackingId);

					client.mutate({
						mutation: gql`mutation FacebookTracked {
							facebookTracked
						}`,
					});
				} else {
					Mixpanel.identify(response.data.facebookIdentifyWithCode.trackingId);
				}

				Facebook.identify(response.data.facebookIdentifyWithCode.email);

				Mixpanel.setUserProperty({
					"$name": response.data.facebookIdentifyWithCode.name,
					"$email": response.data.facebookIdentifyWithCode.email,
				});

				Mixpanel.track(newSignup ? "User signed up" : "User logged in");

				if (newSignup)
					Facebook.trackFacebookSignup();

				navigate("account");
			}
		}).catch(error => {
			console.log(error);
		});
	}

	loginWithFacebook() {
		// FIXME: Include state?
		window.location.href = `https://www.facebook.com/v3.2/dialog/oauth?client_id=${appId}&redirect_uri=${encodeURIComponent(redirectURI)}&scope=email,ads_read`;
	}

	render() {
		return (
			<div className="facebooklogin-button" onClick={this.loginWithFacebook}>
				<FacebookLogo/>
				<div className="text js-login">Continue with Facebook</div>
			</div>
		);
	}
}

export const FacebookLogin = graphql<FacebookLoginProps>(gql`
	mutation FacebookIdentifyWithCode($facebookCode: String!) {
		facebookIdentifyWithCode(facebookCode: $facebookCode) {
			accessToken
			trackingId
			trackingInit
			name
			email
		}
	}
`)(FacebookLoginImpl);