export const modalPopupStyle = {
	overlay: {
		backgroundColor: "rgba(16, 20, 24, 0.3)", // $AlmostBlack
		overflow: "scroll",
		zIndex: 1000,
	},
	content: {
		boxShadow: "0 16px 32px 0 rgba(16, 20, 24, 0.16)",
		margin: "80px auto",
		maxWidth: "720px",
		padding: "48px",
		
		// Remove presets
		border: "none",
		position: "initial",
		top: "initial",
		left: "initial",
		right: "initial",
		bottom: "initial",
	},
};