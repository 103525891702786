import React from "react";

import "./login.scss";
import { FacebookLogin } from "../components/facebooklogin";
import { ApolloConsumer } from "react-apollo";

interface LoginProps {
	navigate: (newRoute: string) => void;
}

export class Login extends React.Component<LoginProps> {
	render() {
		return (
			<div className="login">
				<div className="heading">Log in to your Noora account.</div>
				<ApolloConsumer>
					{client => <FacebookLogin client={client} navigate={this.props.navigate}/>}
				</ApolloConsumer>
			</div>
		);
	}
}