import React from "react";
import Modal from "react-modal";
import moment from "moment";

import visa from "payment-icons/min/flat/visa.svg";
import mastercard from "payment-icons/min/flat/mastercard.svg";
import amex from "payment-icons/min/flat/amex.svg";
import diners from "payment-icons/min/flat/diners.svg";
import discover from "payment-icons/min/flat/discover.svg";
import jcb from "payment-icons/min/flat/jcb.svg";
import unionpay from "payment-icons/min/flat/unionpay.svg";

import "./subscriptioninfo.scss";
import { ReactivateSubscription } from "../containers/reactivatesubscription";
import { CancelSubscription } from "../containers/cancelsubscription";
import { InvoicesContainer } from "../containers/invoices";
import { UpdateBillingDetailsContainer } from "../containers/updatebillingdetails";
import { Button } from "./core/button";
import { modalPopupStyle } from "./core/modal";
import { UpdateBillingDetails } from "./updatebillingdetails";
import { UpdatePaymentMethod } from "./updatepaymentmethod";
import { BillingDetailsInfo } from "./billingdetails";
import { billingCountries } from "../thirdparty/countries";
import { InfoBox } from "./core/infobox";

interface SubscriptionInfoProps {
	startedAt: Date;
	nextRenewalAt: Date;
	status: string;
	cardBrand: string;
	cardExpiry: string;
	card4Digits: string;
}

interface SubscriptionInfoState {
	activeModalId: string;
}

function getAssetFromBrand(brand: string) {
	switch (brand) {
		case "Visa":
			return visa;
		case "MasterCard":
			return mastercard;
		case "American Express":
			return amex;
		case "Diners Club":
			return diners;
		case "Discover":
			return discover;
		case "JCB":
			return jcb;
		case "UnionPay":
			return unionpay;
		default:
			return null;
	}
}

interface BillingDetailsViewProps {
	billingDetails: BillingDetailsInfo;
}

class BillingDetailsViewImpl extends React.Component<BillingDetailsViewProps> {
	render() {
		return (
			<div>
				<div className="row">{this.props.billingDetails.businessName}</div>	
				<div className="row">{this.props.billingDetails.addressLine1}</div>
				<div className="row">{this.props.billingDetails.addressLine2}</div>
				<div className="row">{this.props.billingDetails.city} {this.props.billingDetails.state} {this.props.billingDetails.postalCode}</div>
				<div className="row">{billingCountries[this.props.billingDetails.country]}</div>
				<div className="row">{this.props.billingDetails.taxId}</div>
			</div>
			
		);
	}
}

const BillingDetailsView = UpdateBillingDetailsContainer(BillingDetailsViewImpl);

export class SubscriptionInfo extends React.Component<SubscriptionInfoProps, SubscriptionInfoState> {
	constructor(props) {
		super(props);
		this.state = {
			activeModalId: "",
		};

		this.onChangeBillingDetails = this.onChangeBillingDetails.bind(this);
		this.onChangePaymentDetails = this.onChangePaymentDetails.bind(this);
		this.closeActiveModal = this.closeActiveModal.bind(this);
	}

	onChangeBillingDetails() {
		this.setState({
			activeModalId: "billingdetails",
		});
	}

	onChangePaymentDetails() {
		this.setState({
			activeModalId: "paymentdetails",
		});
	}

	closeActiveModal() {
		this.setState({
			activeModalId: "",
		});
	}

	render() {
		let brandIcon = getAssetFromBrand(this.props.cardBrand);

		return (
			<div className="subscriptioninfo js-subscriptioninfo">
				{this.props.status === "CancellingAtEndOfPeriod" ?
						<div className="info">
							<InfoBox
								title="Your subscription has been cancelled!"
								warning={true}
								fragments={[{
									text: `You still have access to your account until ${moment(this.props.nextRenewalAt).format("Do MMMM YYYY")}. You can re-activate your subscription at any time or read more about how Noora's pricing works `,
								}, {
									text: "here.",
									link: "https://www.getnoora.com/pricing"
								}]}
								action={<ReactivateSubscription/>}
							/>
						</div>
					:
						<div className="info">
							<div className="section">
								<h1>Billing details</h1> 
								<BillingDetailsView/>
								<Button link={true} classes="js-billingdetails-change" text="Change" onClick={this.onChangeBillingDetails}/>
							</div>
							<div className="section">
								<h1>Payment method</h1>
								<div className="row">{brandIcon ? <img className="cardicon" src={brandIcon}></img> : this.props.cardBrand} •••• •••• •••• {this.props.card4Digits}</div>
								<div className="row js-card-expiry">Expires: {this.props.cardExpiry}</div>
								<Button link={true} classes="js-paymentdetails-change" text="Change" onClick={this.onChangePaymentDetails}/>
							</div>
							<div className="section">
								<h1>Next renewal date</h1> 
								<div className="row js-renewal-date">{moment(this.props.nextRenewalAt).format("Do MMMM YYYY")}</div>
								<div className="row">Noora service</div>
								<CancelSubscription/>
							</div>	
							<div className="section mobile">
								<p>Note: You can change your billing details and payment method by logging in on your desktop computer.</p>
							</div>	
						</div>				
				}
				<InvoicesContainer/>
				<Modal 
					isOpen={this.state.activeModalId === "billingdetails"}
					onRequestClose={this.closeActiveModal}
					style={modalPopupStyle}
				>
					<UpdateBillingDetails onClose={this.closeActiveModal}/>
				</Modal>
				<Modal 
					isOpen={this.state.activeModalId === "paymentdetails"}
					onRequestClose={this.closeActiveModal}
					style={modalPopupStyle}
				>
					<UpdatePaymentMethod onClose={this.closeActiveModal}/>
				</Modal>
			</div>
		);
	}
}