import React from "react";
import { Query, QueryResult } from "react-apollo";

import { billingInvoicesQuery } from "../graphql/billing";
import { Invoices } from "../components/invoices";

export class InvoicesContainer extends React.Component {
	render() {
		return (
			<Query
				query={billingInvoicesQuery}
				fetchPolicy={"network-only"}
			>
				{(result: QueryResult) => {
					if (result.loading)
						return null; // FIXME: Loading spinner.
						
					return <Invoices invoices={result.data.billingInvoices}/>
				}}
			</Query>
		);
	}
}