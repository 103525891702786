import React from "react";
import { Mutation } from "react-apollo";

import { BillingDetails, BillingDetailsInfo } from "./billingdetails";
import { Button } from "./core/button";
import moment from "moment";
import { PaymentDetails } from "./paymentdetails";
import { InvoicePreviewContainer } from "../containers/invoicepreview";
import "./checkout.scss";
import { createStripeToken } from "../thirdparty/stripe";
import { startSubscriptionMutation, updateBillingDetailsMutation} from "../graphql/billing";
import { ApolloError } from "apollo-client";
import { InfoBox } from "./core/infobox";

import * as Mixpanel from "../mixpanel";

const requiredFieldError = "This field is required.";

interface CheckoutProps {
	billingDetails: BillingDetailsInfo;
	trialExpires: number;
}

export class Checkout extends React.Component<CheckoutProps, any> {
	constructor(props) {
		super(props);
		this.onBillingDetailChange = this.onBillingDetailChange.bind(this);
		this.onPaymentDetailsChange = this.onPaymentDetailsChange.bind(this);
		this.onStripeReady = this.onStripeReady.bind(this);
		this.onSubmitOrder = this.onSubmitOrder.bind(this);
		this.onCheckoutError = this.onCheckoutError.bind(this);
		this.onBack = this.onBack.bind(this);

		this.state = {
			loading: false,
			stripe: null,
			cardElementRef: null,
			isOnPaymentForm: false,
			...this.props.billingDetails,
			errors: {},
		};
	}

	onStripeReady(stripe, cardElementRef) {
		this.setState({
			stripe: stripe,
			cardElementRef: cardElementRef,
		});
	}

	onBillingDetailChange(property: string, newValue: string) {
		if (this.state.loading)
			return;

		this.setState({
			[property]: newValue,
		});
	}

	onPaymentDetailsChange(property: string, newValue: string) {
		if (this.state.loading)
			return;

		this.setState({
			[property]: newValue,
		});
	}

	getBillingDetails() {
		return {
			cardHolderName: this.state.cardHolderName,
			businessName: this.state.businessName,
			addressLine1: this.state.addressLine1,
			addressLine2: this.state.addressLine2,
			city: this.state.city,
			state: this.state.state,
			postalCode: this.state.postalCode,
			country: this.state.country,
			taxId: this.state.taxId,
		};
	}

	onCheckoutError(error: ApolloError) {
		let errors = this.state.errors;
		errors["general"] = error.graphQLErrors[0].message;

		this.setState({
			loading: false,
			errors: errors,
		});
	}

	onBack() {
		this.setState({
			isOnPaymentForm: false,
		});
	}

	onSubmitDetails(mutation: any) {
		let billingInfo = this.getBillingDetails();

		let errors = {};
		if (!billingInfo.addressLine1)
			errors["addressLine1"] = requiredFieldError;
		if (!billingInfo.city)
			errors["city"] = requiredFieldError;
		if (!billingInfo.postalCode)
			errors["postalCode"] = requiredFieldError;
		if (!billingInfo.country)
			errors["country"] = requiredFieldError;

		if (Object.keys(errors).length >= 1) {
			this.setState({
				errors: errors,
			});

			return;
		} else {
			this.setState({
				errors: {},
			});
		}

		let checkoutInstance = this;
		
		checkoutInstance.setState({
			loading: true,
		});

		Mixpanel.track("Submitted checkout billing details");
		
		mutation({ 
			variables: {
				details: {
					businessName: this.state.businessName,
					addressLine1: this.state.addressLine1,
					addressLine2: this.state.addressLine2,
					city: this.state.city,
					state: this.state.state,
					postalCode: this.state.postalCode,
					country: this.state.country,
					taxId: this.state.taxId,
				},
			},
			update: (store, { data: { updateBillingDetails } }) => {
				if (updateBillingDetails) {
					checkoutInstance.setState({
						isOnPaymentForm: true,
						loading: false,
					});
				}
			},
			refetchQueries: [ "BillingDetails", "PreviewNextInvoiceQuery" ],
		});
	}

	onSubmitOrder(mutation: any) {
		let checkoutInstance = this;

		let billingInfo = this.getBillingDetails();

		let errors = {};
		if (!billingInfo.cardHolderName)
			errors["cardHolderName"] = requiredFieldError;

		if (Object.keys(errors).length >= 1) {
			this.setState({
				errors: errors,
			});

			return;
		} else {
			this.setState({
				errors: {},
			});
		}
		
		checkoutInstance.setState({
			loading: true,
		});

		function stopLoading() {
			checkoutInstance.setState({
				loading: false,
			});
		}

		Mixpanel.track("Submitted checkout payment details");

		createStripeToken(this.state.stripe, billingInfo, function(error, token) {
			if (!token) {
				stopLoading();
				errors["general"] = error.message;
				return; 
			}

			mutation({
				variables: {
					token: token.token.id,
				},
				update: (store, { data: { startSubscription } }) => {
					if (startSubscription.paymentIntentSecret) {	
						Mixpanel.track("Redirected to secure checkout");

						checkoutInstance.state.stripe.confirmPaymentIntent(
							startSubscription.paymentIntentSecret,
							checkoutInstance.state.cardElementRef, {
								return_url: process.env.REACT_APP_STRIPE_PAYMENT_URL,
							},
						).then(function(result) {
							if (result.paymentIntent) {
								let action = result.paymentIntent.next_action;
								if (action && action.type === 'redirect_to_url')
									window.location = action.redirect_to_url.url;
							} else {
								stopLoading();
							}
						});
					} else {
						if (startSubscription.success)
							Mixpanel.track("Upgraded to paid subscription");
						else
							Mixpanel.track("Failed to upgrade to paid subscription");

						stopLoading();
					}
				},
				refetchQueries: [ "BillingSubscriptionQuery" ],
			});
		});
	}

	render() {
		const trialExpiryDate = moment(this.props.trialExpires);
		const trialIsExpired = trialExpiryDate.isBefore(moment());

		return (
			<div className="checkout">
				<div className="info">
					<InfoBox
						title={trialIsExpired ? "Your trial has expired!" : `Your trial expires on ${trialExpiryDate.format("Do MMMM YYYY")}`}
						fragments={[{
							text: "Don't worry, once your trial expires, all of your data will be waiting for you if you decide to upgrade to a paid subscription. You can read about how Noora's pricing works ",
						}, {
							text: "here.",
							link: "https://www.getnoora.com/pricing"
						}]}
					/>
				</div>
				<div className="checkoutform">
					{this.state.isOnPaymentForm ?
							<div className="form">
								<h1>2. Payment method</h1>
								<PaymentDetails
									cardHolderName={this.state.cardHolderName}
									onChange={this.onPaymentDetailsChange}
									onStripeReady={this.onStripeReady}
									errors={this.state.errors}
								/>
								<div className="buttonbox">
									<Button
										classes="js-button-back"
										text="Back"
										disabled={!this.state.stripe || this.state.loading}
										onClick={() => this.onBack()}
									/>
									<Mutation 
										mutation={startSubscriptionMutation}
										onError={this.onCheckoutError}
									>
										{(startSubscriptionMutation) => {
											return (
												<Button
													classes="js-button-checkout"
													text="Submit your order"
													confirm={true}
													loading={this.state.loading}
													disabled={!this.state.stripe || this.state.loading}
													onClick={() => {this.onSubmitOrder(startSubscriptionMutation)}}
												/>
											);
										}}
									</Mutation>	
								</div>
							</div>
						:
							<div className="form">
								<h1>1. Billing details</h1>
								<BillingDetails
									onChange={this.onBillingDetailChange}
									cardHolderName={this.state.cardHolderName}
									businessName={this.state.businessName}
									addressLine1={this.state.addressLine1}
									addressLine2={this.state.addressLine2}
									city={this.state.city}
									state={this.state.state}
									postalCode={this.state.postalCode}
									country={this.state.country}
									taxId={this.state.taxId}
									errors={this.state.errors}
								/>
								<div className="buttonbox">
									<Mutation mutation={updateBillingDetailsMutation}>
										{(updateBillingDetailsMutation) => {
											return (
												<Button
													classes="js-button-proceed"
													text="Proceed to payment details"
													confirm={true}
													loading={this.state.loading}
													disabled={this.state.loading}
													onClick={() => {this.onSubmitDetails(updateBillingDetailsMutation)}}
												/>
											);
										}}
									</Mutation>
								</div>							
							</div>
					}
					<div className="preview">
						<InvoicePreviewContainer/>
					</div>
				</div>
			</div>
		);
	}
}