import React from "react";
import { Mutation } from "react-apollo";

import { startSubscriptionMutation } from "../graphql/billing";
import { Button } from "../components/core/button";

import * as Mixpanel from "../mixpanel";

export class ReactivateSubscription extends React.Component {
	state = {
		processing: false,
	};

	render() {
		return (
			<Mutation mutation={startSubscriptionMutation}>
				{(startSubscriptionMutation) => {
					return <Button confirm={true} classes="js-subscription-reactivate" text="Re-activate subscription" loading={this.state.processing} onClick={() => {
						this.setState({ processing: true });

						Mixpanel.track("Re-activated paid subscription");

						startSubscriptionMutation({
							update: () => {
								this.setState({ processing: false });
							},
							refetchQueries: [ "BillingSubscriptionQuery" ],
						});
					}}/>;
				}}
			</Mutation>
		);
	}
}