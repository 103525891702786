import React from "react";
import { Mutation } from "react-apollo";
import _ from "underscore";

import "./connectedaccount.scss";
import { FacebookAdAccount } from "./connectedaccounts";
import { adAccountsQuery, updateAdAccountConnectionMutation } from "../graphql/account";
import { Toggle } from "./core/toggle";

interface ConnectedAccountProps extends FacebookAdAccount {

}

export class ConnectedAccount extends React.Component<ConnectedAccountProps> {
	render() {
		return (
			<div className="connectedaccount">
				<div className="content">
					<div className="info">
						<div className="title">{this.props.name}</div>
						<div className="subtext">{this.props._id}</div>
					</div>
					<div className="info">
						<div className="title">{this.props.billableSpend} {this.props.currency}</div>
						<div className="subtext">Spend in the past 30 days</div>
					</div>
				</div>
				<div className="action">
					<Mutation mutation={updateAdAccountConnectionMutation}>
						{(updateAdAccountConnectionMutation) => {
							return <Toggle on={this.props.connectedToUser} onToggle={() => {
								updateAdAccountConnectionMutation({
									variables: {
										adAccountId: this.props._id,
										connect: !this.props.connectedToUser,
									},
									optimisticResponse: {
										updateFacebookAdAccountConnection: _.extend(this.props, {
											connectedToUser: !this.props.connectedToUser,
											__typename: "FacebookAccount",
										}),
									},
									refetchQueries: [ "FacebookAccounts" ],
								});
							}}/>
						}}
					</Mutation>
				</div>
			</div>
		);
	}
}