import React from "react";
import { Mutation, Query, QueryResult } from "react-apollo";
import { CardElement, Elements, injectStripe , StripeProvider} from "react-stripe-elements";

import { BillingDetails, BillingDetailsInfo } from "../components/billingdetails";
import { updateBillingTokenMutation, billingDetailsQuery, startSubscriptionMutation } from "../graphql/billing";
import { Button } from "../components/core/button";
import { inherits } from "util";

export async function createStripeToken(stripe, billingDetails: BillingDetailsInfo, onComplete: (error, token) => void) {
	stripe.createToken({
		name: billingDetails.cardHolderName,
		address_line1: billingDetails.addressLine1,
		address_line2: billingDetails.addressLine2,
		address_city: billingDetails.city,
		address_state: billingDetails.state,
		address_zip: billingDetails.postalCode,
		address_country: billingDetails.country,
	})
	.then(function(token) {
		onComplete(null, token);
	})
	.catch(function(error) {
		onComplete(error, null);
	});
}

interface CardDetailsImplProps extends BillingDetailsInfo {
	onStripeReady: (stripe, cardElementRef) => void;
	stripe: any;
}

const stripeStyling = {
	base: {
		color: "#101418",
		fontFamily: "Source Sans Pro",
		fontSize: "18px",
		fontSmoothing: "antialiased",
	},
	empty: {
		color: "#787F8C",
	},
	invalid: {
		color: "#E6653E"
	},
};

class CardDetailsImpl extends React.Component<CardDetailsImplProps> {
	cardElementRef = null;

	constructor(props) {
		super(props);
		this.onReady = this.onReady.bind(this);
	}

	onReady(element) {
		this.cardElementRef = element;
		this.props.onStripeReady(this.props.stripe, this.cardElementRef);
	}

	render() {
		return (
			<div className="carddetails js-carddetails">
				<CardElement style={stripeStyling} hidePostalCode={true} onReady={this.onReady}/>
			</div>
		);
	}
}

const CardDetails = injectStripe(CardDetailsImpl);

interface PaymentProps {
	onStripeReady: (stripe, cardElementRef) => void;
	error?: string;
}

export class Payment extends React.Component<PaymentProps> {
	render() {
		let classNames = [ "input" ];
		if (this.props.error)
			classNames.push("is-error");

		return (
			<div>
				<div className={classNames.join(" ")}>
					<StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}>
						<Elements>
							<CardDetails onStripeReady={this.props.onStripeReady}/>
						</Elements>
					</StripeProvider>				
					{this.props.error ?
						<div className="error js-card-errors">
							{this.props.error}
						</div>
					:
						null
					}
				</div>

			</div>
		);
	}
}