import React from "react";

import "./divider.scss";

interface DividerProps {
	compact?: boolean;
}

export class Divider extends React.Component<DividerProps> {
	render() {
		let classNames = [ "divider" ];
		if (this.props.compact)
			classNames.push("is-compact");

		return (
			<div className={classNames.join(" ")}/>
		);
	}
}