import React from "react";
import { Mutation } from "react-apollo";
import { ApolloError } from "apollo-client";

import { BillingDetailsInfo } from "./billingdetails";
import { Button } from "./core/button";
import { Divider } from "./core/divider";
import { PaymentDetails } from "./paymentdetails";
import { createStripeToken } from "../thirdparty/stripe";
import { updateBillingTokenMutation} from "../graphql/billing";
import { UpdateBillingDetailsContainer } from "../containers/updatebillingdetails";

const requiredFieldError = "This field is required.";

interface UpdatePaymentMethodProps {
	onClose: () => void;
	billingDetails: BillingDetailsInfo;
}

export class UpdatePaymentMethodImpl extends React.Component<UpdatePaymentMethodProps, any> {
	constructor(props) {
		super(props);
		this.onSubmitPaymentMethod = this.onSubmitPaymentMethod.bind(this);
		this.onPaymentDetailsChange = this.onPaymentDetailsChange.bind(this);
		this.onStripeReady = this.onStripeReady.bind(this);
		this.onCheckoutError = this.onCheckoutError.bind(this);

		this.state = {
			loading: false,
			stripe: null,
			cardElementRef: null,
			isOnPaymentForm: false,
			...this.props.billingDetails,
			errors: {},
		};
	}

	onStripeReady(stripe, cardElementRef) {
		this.setState({
			stripe: stripe,
			cardElementRef: cardElementRef,
		});
	}

	onPaymentDetailsChange(property: string, newValue: string) {
		if (this.state.loading)
			return;

		this.setState({
			[property]: newValue,
		});
	}

	getBillingDetails() {
		return {
			cardHolderName: this.state.cardHolderName,
			businessName: this.state.businessName,
			addressLine1: this.state.addressLine1,
			addressLine2: this.state.addressLine2,
			city: this.state.city,
			state: this.state.state,
			postalCode: this.state.postalCode,
			country: this.state.country,
			taxId: this.state.taxId,
		};
	}

	onCheckoutError(error: ApolloError) {
		let errors = this.state.errors;
		errors["general"] = error.graphQLErrors[0].message;

		this.setState({
			loading: false,
			errors: errors,
		});
	}

	onSubmitPaymentMethod(mutation: any) {
		let checkoutInstance = this;

		let billingInfo = this.getBillingDetails();

		let errors = {};
		if (!billingInfo.cardHolderName)
			errors["cardHolderName"] = requiredFieldError;

		if (Object.keys(errors).length >= 1) {
			this.setState({
				errors: errors,
			});

			return;
		} else {
			this.setState({
				errors: {},
			});
		}
		
		checkoutInstance.setState({
			loading: true,
		});

		function stopLoading() {
			checkoutInstance.setState({
				loading: false,
			});
		}

		createStripeToken(this.state.stripe, billingInfo, function(error, token) {
			if (!token) {
				stopLoading();
				errors["general"] = error.message;
				return; 
			}

			mutation({
				variables: {
					token: token.token.id,
				},
				update: (store, { data: { updateBillingToken } }) => {
					if (updateBillingToken) {
						checkoutInstance.props.onClose();
					}
				},
				refetchQueries: [ "BillingSubscriptionQuery" ],
			});
		});
	}

	render() {
		return (
			<div className="form">
				<h1>Change payment method</h1>
				<PaymentDetails
					cardHolderName={this.state.cardHolderName}
					onChange={this.onPaymentDetailsChange}
					onStripeReady={this.onStripeReady}
					errors={this.state.errors}
				/>
				<Mutation 
					mutation={updateBillingTokenMutation}
					onError={this.onCheckoutError}
				>
					{(updateBillingTokenMutation) => {
						return (
							<Button
								text="Save changes"
								classes="js-button-save"
								confirm={true}
								loading={this.state.loading}
								disabled={!this.state.stripe || this.state.loading}
								onClick={() => {this.onSubmitPaymentMethod(updateBillingTokenMutation)}}
							/>
						);
					}}
				</Mutation>	
			</div>
		);
	}
}

export const UpdatePaymentMethod = UpdateBillingDetailsContainer(UpdatePaymentMethodImpl);