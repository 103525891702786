import React from "react";
import Select from "react-select";

import "./billingdetails.scss";
import { Input } from "./core/input";
import { FormEntry } from "./core/form";
import { billingCountries, getBillingCountryOptions } from "../thirdparty/countries";
import { RadioButtonGroup } from "./core/radiobuttons";

export interface BillingDetailsInfo {
	cardHolderName: string;
	businessName: string;
	addressLine1: string;
	addressLine2: string;
	city: string;
	state: string;
	postalCode: string;
	country: string;
	taxId: string;
}

interface BillingDetailsProps extends BillingDetailsInfo {
	onChange: (property: string, newValue: string) => void; // Callback for when the user changes a value
	errors: { [propertyName: string]: string };
}

interface BillingDetailsState {
	isBusinessPurchase?: boolean;
	errors: { [propertyName: string]: string };
}

const selectBoxStyling = {
	control: (styles, {isDisabled, isFocused}) => {
		return {
			...styles,
			borderColor: isDisabled ? "#CFD0D1" : isFocused ? "#3D6AA7" : "#CFD0D1",
			boxShadow: isDisabled ? null : isFocused ? "0 0 0 4px rgba(150, 188, 223, 0.5)" : null,
			cursor: isDisabled ? "not-allowed" : "pointer",
			"&:hover": {
				borderColor: isDisabled ? "#CFD0D1" : isFocused ? "#3D6AA7" : "#CFD0D1",
			}
		};
	},
	option: (styles, {isDisabled, isFocused, isSelected}) => {
		return {
			...styles,
			backgroundColor: isDisabled ? null : isSelected ? "#273472" : isFocused ? "#DFEAF5" : null,
			color: isDisabled ? "#CFD0D1" : isSelected ? "white" : "#101418",
      		cursor: isDisabled ? "not-allowed" : "pointer",
		};
	},
	singleValue: (styles) => {
		return {
			...styles,
			color: "#101418",
			paddingLeft: 2,
			paddingRight: 2,
		};
	},
	input: (styles) => {
		return {
			...styles,
			color: "#101418",
			paddingLeft: 2,
			paddingRight: 2,
		};
	},
	dropdownIndicator: (styles) => {
		return {
			...styles,
			paddingBottom: 9,
			paddingLeft: 9,
			paddingRight: 9,
			paddingTop: 9,
		};
	},
}

export class BillingDetails extends React.Component<BillingDetailsProps, BillingDetailsState> {
	state = {
		isBusinessPurchase: !!this.props.taxId,
		errors: this.props.errors,
	};

	constructor(props) {
		super(props);

		this.onBusinessNameChange = this.onBusinessNameChange.bind(this);
		this.onAddressLine1Change = this.onAddressLine1Change.bind(this);
		this.onAddressLine2Change = this.onAddressLine2Change.bind(this);
		this.onCityChange = this.onCityChange.bind(this);
		this.onStateChange = this.onStateChange.bind(this);
		this.onPostalCodeChange = this.onPostalCodeChange.bind(this);
		this.onCountryChange = this.onCountryChange.bind(this);
		this.onTaxIdChange = this.onTaxIdChange.bind(this);
		this.onBusinessPurchaseChange = this.onBusinessPurchaseChange.bind(this);
	}

	componentDidUpdate() {
		if (this.state.errors === this.props.errors)
			return;

		this.setState({
			errors: this.props.errors,
		});
	}

	clearErrorsForProperty(propertyName: string) {
		let errors = this.state.errors;
		delete errors[propertyName];

		this.setState({
			errors: errors,
		});
	}

	onBusinessNameChange(event) {
		this.props.onChange("businessName", event.target.value);
		this.clearErrorsForProperty("businessName");
	}

	onAddressLine1Change(event) {
		this.props.onChange("addressLine1", event.target.value);
		this.clearErrorsForProperty("addressLine1");
	}

	onAddressLine2Change(event) {
		this.props.onChange("addressLine2", event.target.value);
	}

	onCityChange(event) {
		this.props.onChange("city", event.target.value);
		this.clearErrorsForProperty("city");
	}

	onStateChange(event) {
		this.props.onChange("state", event.target.value);
	}

	onPostalCodeChange(event) {
		this.props.onChange("postalCode", event.target.value);
		this.clearErrorsForProperty("postalCode");
	}

	onCountryChange(option) {
		this.props.onChange("country", option.value);
		this.clearErrorsForProperty("country");
	}

	onTaxIdChange(event) {
		this.props.onChange("taxId", event.target.value);
	}

	onBusinessPurchaseChange(newValue: string) {
		const isBusinessPurchase = newValue === "yes";
		this.setState({
			isBusinessPurchase: isBusinessPurchase,
		});

		if (!isBusinessPurchase)
			this.props.onChange("taxId", "");
	}

	render() {
		let dropdownClasses = ["js-dropdown-country", "dropdown"];
		if (this.state.errors["country"])
			dropdownClasses.push("is-error");

		return (
			<div className="billingdetails">
				<div className="entry">
					<FormEntry label="Country">
						<div className="input js-address-country">
							<Select
								className={dropdownClasses.join(" ")}
								classNamePrefix={"js-dropdown"}
								value={{
									value: this.props.country,
									label: billingCountries[this.props.country],
								}}
								onChange={this.onCountryChange}
								isSearchable={true}
								placeholder="Choose a country..."
								options={getBillingCountryOptions()}
								styles={selectBoxStyling}
							/>	
							{this.state.errors["country"] ?
									<div className="error">
										{this.state.errors["country"]}
									</div>
								:
									null
							}						
						</div>
					</FormEntry>
				</div>
				<div className="entry">
					<FormEntry label="Company name" optional={true}>
						<Input value={this.props.businessName} error={this.state.errors["businessName"]} onChange={this.onBusinessNameChange}/>
					</FormEntry>
				</div>
				<div className="entry">
					<FormEntry label="Street address">
						<Input value={this.props.addressLine1} classes="js-address-street" error={this.state.errors["addressLine1"]} onChange={this.onAddressLine1Change}/>
					</FormEntry>
					<FormEntry label="Suite / Unit" optional={true}>
						<Input value={this.props.addressLine2} onChange={this.onAddressLine2Change}/>
					</FormEntry>
				</div>
				<div className="entry">
					<FormEntry label="City">
						<Input value={this.props.city} classes="js-address-city" error={this.state.errors["city"]} onChange={this.onCityChange}/>
					</FormEntry>
					<FormEntry label="State / Province" optional={true}>
						<Input value={this.props.state} onChange={this.onStateChange}/>
					</FormEntry>
				</div>
				<div className="entry">
					<FormEntry label="Postal code" short={true}>
						<Input value={this.props.postalCode} classes="js-address-postalcode" error={this.state.errors["postalCode"]} onChange={this.onPostalCodeChange}/>
					</FormEntry>
				</div>
				<div className="entry">
					<FormEntry label="Is this a business purchase?">
						<RadioButtonGroup
							value={this.state.isBusinessPurchase ? "yes" : "no"}
							options={[{
								classes: "js-business-yes",
								value: "yes",
								label: "Yes",
							}, {
								classes: "js-business-no",
								value: "no",
								label: "No",
							}]}
							onChange={this.onBusinessPurchaseChange}
						/>
					</FormEntry>
				</div>
				{this.state.isBusinessPurchase ?
						<div className="entry">
							<FormEntry label="VAT ID" short={true}>
								<Input value={this.props.taxId} classes="js-address-vat" onChange={this.onTaxIdChange}/>
							</FormEntry>
						</div>
					:
						null
				}
				</div>
		);
	}
}