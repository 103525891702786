import React from "react";

import "./radiobuttons.scss";

interface RadioButtonGroupOption {
	classes?: string;
	value: string;
	label: string;
}

interface RadioButtonGroupProps {
	value: string;
	options: RadioButtonGroupOption[];
	onChange: (value: string) => void;
}

export class RadioButtonGroup extends React.Component<RadioButtonGroupProps> {
	render() {
		return (
			<div className="radiobuttongroup">
				{this.props.options.map(option => {
					let classNames = ["radiobutton"];
					if (option.classes)
						classNames.push(option.classes);

					return (
						<div key={option.value} className={classNames.join(" ")}>
							<input 
								type="radio" value={option.value} 
								checked={this.props.value === option.value} name={option.label}
								onChange={(event) => {
									this.props.onChange(event.target.value);
								}}
							/>
							{option.label}
						</div>

					);
				})}
			</div>
		);
	}
}