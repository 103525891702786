import React from "react";
import { Mutation } from "react-apollo";

import { BillingDetails, BillingDetailsInfo } from "./billingdetails";
import { Button } from "./core/button";
import { Divider } from "./core/divider";
import { updateBillingDetailsMutation} from "../graphql/billing";
import { UpdateBillingDetailsContainer } from "../containers/updatebillingdetails";

const requiredFieldError = "This field is required.";

interface UpdateBillingDetailsProps {
	onClose: () => void;
	billingDetails: BillingDetailsInfo;
}

class UpdateBillingDetailsImpl extends React.Component<UpdateBillingDetailsProps, any> {
	constructor(props) {
		super(props);
		this.onBillingDetailChange = this.onBillingDetailChange.bind(this);

		this.state = {
			loading: false,
			...this.props.billingDetails,
			errors: {},
		};
	}
	onBillingDetailChange(property: string, newValue: string) {
		if (this.state.loading)
			return;

		this.setState({
			[property]: newValue,
		});
	}

	getBillingDetails() {
		return {
			cardHolderName: this.state.cardHolderName,
			businessName: this.state.businessName,
			addressLine1: this.state.addressLine1,
			addressLine2: this.state.addressLine2,
			city: this.state.city,
			state: this.state.state,
			postalCode: this.state.postalCode,
			country: this.state.country,
			taxId: this.state.taxId,
		};
	}

	onSubmitDetails(mutation: any) {
		let billingInfo = this.getBillingDetails();

		let errors = {};
		if (!billingInfo.addressLine1)
			errors["addressLine1"] = requiredFieldError;
		if (!billingInfo.city)
			errors["city"] = requiredFieldError;
		if (!billingInfo.postalCode)
			errors["postalCode"] = requiredFieldError;
		if (!billingInfo.country)
			errors["country"] = requiredFieldError;

		if (Object.keys(errors).length >= 1) {
			this.setState({
				errors: errors,
			});

			return;
		} else {
			this.setState({
				errors: {},
			});
		}

		let checkoutInstance = this;
		
		checkoutInstance.setState({
			loading: true,
		});
		
		mutation({ 
			variables: {
				details: {
					businessName: this.state.businessName,
					addressLine1: this.state.addressLine1,
					addressLine2: this.state.addressLine2,
					city: this.state.city,
					state: this.state.state,
					postalCode: this.state.postalCode,
					country: this.state.country,
					taxId: this.state.taxId,
				},
			},
			update: (store, { data: { updateBillingDetails } }) => {
				if (updateBillingDetails) {
					checkoutInstance.setState({
						loading: false,
					});

					this.props.onClose();
				}
			},
			refetchQueries: [ "BillingDetails" ],
		});
	}

	render() {
		return (
			<div className="form">
				<h1>Change billing details</h1>
				<BillingDetails
					onChange={this.onBillingDetailChange}
					cardHolderName={this.state.cardHolderName}
					businessName={this.state.businessName}
					addressLine1={this.state.addressLine1}
					addressLine2={this.state.addressLine2}
					city={this.state.city}
					state={this.state.state}
					postalCode={this.state.postalCode}
					country={this.state.country}
					taxId={this.state.taxId}
					errors={this.state.errors}
				/>
				<Mutation mutation={updateBillingDetailsMutation}>
					{(updateBillingDetailsMutation) => {
						return (
							<Button
								text="Save changes"
								classes="js-button-save"
								confirm={true}
								loading={this.state.loading}
								disabled={this.state.loading}
								onClick={() => {this.onSubmitDetails(updateBillingDetailsMutation)}}
							/>
						);
					}}
				</Mutation>								
			</div>
		);
	}
}

export const UpdateBillingDetails = UpdateBillingDetailsContainer(UpdateBillingDetailsImpl);