import React from "react";
import { Query, QueryResult } from "react-apollo";

import { previewNextInvoiceQuery } from "../graphql/billing";
import { InvoicePreview } from "../components/invoicepreview";

export class InvoicePreviewContainer extends React.Component {
	render() {
		return (
			<Query
				query={previewNextInvoiceQuery}
				fetchPolicy={"network-only"}
			>
				{(result: QueryResult) => {
					if (result.loading || !result.data)
						return null; // FIXME: Loading spinner.

					return <InvoicePreview
						subTotal={result.data.previewNextInvoice.subTotal.toFixed(2)}
						tax={result.data.previewNextInvoice.tax.toFixed(2)}
						taxPercent={result.data.previewNextInvoice.taxPercent}
						total={result.data.previewNextInvoice.total.toFixed(2)}
						perAccount={result.data.previewNextInvoice.perAccount}
					/>;
				}}
			</Query>
		);
	}
}