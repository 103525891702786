import React from "react";

import "./infobox.scss";

interface InfoBoxFragments {
	text: string;
	link?: string;
}

interface InfoBoxProps {
	warning?: boolean;
	title: string;
	fragments: InfoBoxFragments[];
	action?: JSX.Element;
}

export class InfoBox extends React.Component<InfoBoxProps> {
	render() {
		return (
			<div className={`infobox ${this.props.warning ? "is-warning" : ""}`}>
				<div className="content">
					<div className="title">{this.props.title}</div>
					<div className="fragments">
					{this.props.fragments.map((fragment, index) => {
						return (
							<span key={index}>
								{fragment.link ? 
										<a href={fragment.link}>{fragment.text}</a>
									:
										fragment.text
								}								
							</span>
						);
					})}	
					</div>
				</div>
				{this.props.action ?
						<div className="action">
							{this.props.action}
						</div>				
					:
						null
				}
			</div>
		);
	}
}