import React from "react";
import { Query, QueryResult } from "react-apollo";

import { activeUserInfoQuery } from "../graphql/account";
import { billingDetailsQuery } from "../graphql/billing";
import { Checkout } from "../components/checkout";
import { BillingDetailsInfo } from "../components/billingdetails";

export class CheckoutContainer extends React.Component {
	render() {
		return (
			<Query
				query={activeUserInfoQuery}
				fetchPolicy="network-only"
			>
				{(accountResult: QueryResult) => {
					if (accountResult.loading)
						return null; // FIXME: Loading spinner.

					if (!accountResult.data || !accountResult.data.loggedInUser)
						return null; // FIXME: Error message.

					return (
						<Query
							query={billingDetailsQuery}
							fetchPolicy="network-only"
						>
						{(result: QueryResult) => {
							if (result.loading)
								return null; // FIXME: Loading spinner.
		
							const previousValues = result.data && result.data.billingDetails ? result.data.billingDetails : null;
		
							let billingDetails: BillingDetailsInfo = {
								cardHolderName: previousValues && previousValues.cardHolderName ? previousValues.name : "",
								businessName: previousValues && previousValues.businessName ? previousValues.businessName : "",
								addressLine1: previousValues && previousValues.addressLine1 ? previousValues.addressLine1 : "",
								addressLine2: previousValues && previousValues.addressLine2 ? previousValues.addressLine2 : "",
								city: previousValues && previousValues.city ? previousValues.city : "",
								state: previousValues && previousValues.state ? previousValues.state : "",
								postalCode: previousValues && previousValues.postalCode ? previousValues.postalCode : "",
								country: previousValues ? previousValues.country : "",
								taxId: previousValues && previousValues.taxId ? previousValues.taxId : "",
							};
		
							return <Checkout billingDetails={billingDetails} trialExpires={accountResult.data.loggedInUser.trialExpires}/>;
						}}
						</Query>
					);
				}}
			</Query>
		);
	}
}