import React from "react";

import { Input } from "./core/input";
import { FormEntry } from "./core/form";
import { Payment } from "../thirdparty/stripe";

interface PaymentDetailsProps {
	cardHolderName?: string;
	onChange: (propertyName: string, newValue: string) => void;
	onStripeReady: (stripe, cardElementRef) => void;
	errors: { [propertyName: string]: string };
}

interface PaymentDetailsState {
	errors: { [propertyName: string]: string };
}

export class PaymentDetails extends React.Component<PaymentDetailsProps, PaymentDetailsState> {
	constructor(props) {
		super(props);
		this.onCardHolderNameChange = this.onCardHolderNameChange.bind(this);
		this.state = {
			errors: this.props.errors,
		};
	}

	componentDidUpdate() {
		if (this.state.errors === this.props.errors)
			return;

		this.setState({
			errors: this.props.errors,
		});
	}

	onCardHolderNameChange(event) {
		this.props.onChange("cardHolderName", event.target.value);

		let errors = this.state.errors;
		delete errors["cardHolderName"];
		this.setState({
			errors: errors,
		});
	}

	render() {
		return (
			<div className="billingdetails">
				<div className="entry">
					<FormEntry label="Cardholder name">
						<Input value={this.props.cardHolderName} classes="js-address-cardholder" error={this.state.errors["cardHolderName"]} onChange={this.onCardHolderNameChange}/>
					</FormEntry>
				</div>
				<div className="entry">
					<FormEntry label="Card details" noFlex={true}>
						<Payment onStripeReady={this.props.onStripeReady} error={this.state.errors["general"]}/>
					</FormEntry>
				</div>
			</div>
		);
	}
}