import React from "react";
import moment from "moment";

import "./invoices.scss";

interface InvoicesProps {
	invoices: [{
		createdAt: number;
		downloadLinkPDF: string;
		stripeInvoiceNumber: string;
		total: string;
	}];
}

export class Invoices extends React.Component<InvoicesProps> {
	render() {
		return (
			<div>
				<h1>Invoices</h1>
				<table className="invoicetable">
					<tbody className="invoicetable-body">
						<tr className="invoicetable-row header">
							<td className="invoicetable-header date">Date</td>
							<td className="invoicetable-header description">Description</td>
							<td className="invoicetable-header reference">Reference</td>
							<td className="invoicetable-header amount">Amount</td>
						</tr>
						{this.props.invoices.map((invoice, index) => {
							return (
								<tr className="invoicetable-row cell" key={index}>
									<td className="invoicetable-cell date"><a href={invoice.downloadLinkPDF}>{moment(invoice.createdAt).format("L")}</a></td>
									<td className="invoicetable-cell description">Noora service</td>
									<td className="invoicetable-cell reference">{invoice.stripeInvoiceNumber}</td>
									<td className="invoicetable-cell amount">{invoice.total}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		);
	}
}