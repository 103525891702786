import React from "react";
import { Query, QueryResult } from "react-apollo";

import { adAccountsQuery } from "../graphql/account";
import { ConnectedAccounts } from "../components/connectedaccounts";

export class AccountContainer extends React.Component {
	render() {
		return (
			<Query
				query={adAccountsQuery}
				fetchPolicy={"network-only"}
			>
				{(result: QueryResult) => {
					if (result.loading || !result.data)
						return null; // FIXME: Loading spinner.
					if (!result.data.facebookAccounts)
						return null; // FIXME: Error state.

					return <ConnectedAccounts accounts={result.data.facebookAccounts.accounts || []}/>;
				}}
			</Query>
		);
	}
}