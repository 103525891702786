import React from "react";
import Modal from "react-modal";

import { ReactComponent as NooraLogo } from "../stylesheet/assets/noora.svg";
import { ReactComponent as IconCross } from "../stylesheet/assets/cross.svg";
import { ReactComponent as IconMenu } from "../stylesheet/assets/menu.svg";
import "./appheader.scss";
import { Button } from "./core/button";
import * as Mixpanel from "../mixpanel";
import { TabBar } from "./core/tabs";
import { modalPopupStyle } from "./core/modal";

interface AppHeaderProps {
	route: string;
	navigate: (newRoute: string) => void;
}

interface AppHeaderState {
	mobileNavigationOpen: boolean;
	downloadModalOpen: boolean;
}

export class AppHeader extends React.Component<AppHeaderProps, AppHeaderState> {
	constructor(props) {
		super(props);
		this.toggleMobileNavigation = this.toggleMobileNavigation.bind(this);
		this.toggleDownloadModalOpen = this.toggleDownloadModalOpen.bind(this);
		this.state = {
			mobileNavigationOpen: false,
			downloadModalOpen: false,
		};
	}

	toggleMobileNavigation(open: boolean) {
		this.setState({
			mobileNavigationOpen: open,
		});
	}

	toggleDownloadModalOpen(open: boolean) {
		this.setState({
			downloadModalOpen: open,
		});
	}

	render() {
		const appHeaderClasses = [ "appheader-navigation" ];
		if (this.state.mobileNavigationOpen)
			appHeaderClasses.push("is-open");

		return (
			<div className="appheader">
				<div className="appheader-content">
					<NooraLogo/>
					{this.props.route !== "login" ?
						<div className={appHeaderClasses.join(" ")}>
							<div className="appheader-mobile-topbar">
								<NooraLogo/>
								<div className="topbar-close" onClick={() => this.toggleMobileNavigation(false)}>
									<IconCross/>
								</div>
							</div>
							<TabBar
								activeTabId={this.props.route}
								tabs={[{
									id: "account",
									label: "Account",
								}, {
									id: "subscription",
									label: "Subscription",
								}]}
								onChange={newTabId => {
									Mixpanel.track("Switched tab", {
										"Tab": newTabId,
									});
									this.toggleMobileNavigation(false);
									this.props.navigate(newTabId);
								}}
							/>
							<div className="appheader-buttonbar">
								<div className="button-download">
									<Button
										compact={true}
										text="Download"
										onClick={() => {
											Mixpanel.track("Opened download modal");
											this.toggleDownloadModalOpen(true);
										}}
									/>
								</div>
								<div className="button-logout">
									<Button
										compact={true}
										text="Log out"
										classes="js-appheader-logout"
										onClick={() => {
											// FIXME: Log out on the server also
											Mixpanel.track("User signed out");
											Mixpanel.reset();

											localStorage.removeItem("userToken");
											localStorage.removeItem("trackingId");
											this.props.navigate("login");
										}}
									/>
								</div>
							</div>
							<div className="appheader-download">
								<a className="downloadlink ios" href="https://itunes.apple.com/app/noora/id1459805140?mt=8" target="_blank"><img alt="Download on the App Store" src="https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2019-05-04&kind=iossoftware&bubble=ios_apps"/></a>
								<a className="downloadlink android" href="https://play.google.com/store/apps/details?id=com.noora&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank"><img alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"/></a>
							</div>
						</div>
						:
							null
					}
					<div className="appheader-mobile-menu" onClick={() => this.toggleMobileNavigation(true)}>
						<IconMenu/>
					</div>
				</div>
				<Modal 
					isOpen={this.state.downloadModalOpen}
					onRequestClose={() => this.toggleDownloadModalOpen(false)}
					style={modalPopupStyle}
				>
					<div className="modal-download">
						<h1>Download Noora</h1>
						<a className="downloadlink ios" href="https://itunes.apple.com/app/noora/id1459805140?mt=8" target="_blank"><img alt="Download on the App Store" src="https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2019-05-04&kind=iossoftware&bubble=ios_apps"/></a>
						<a className="downloadlink android" href="https://play.google.com/store/apps/details?id=com.noora&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank"><img alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"/></a>
					</div>
				</Modal>
			</div>

		);
	}
}