import React from "react";
import { Query, QueryResult } from "react-apollo";

import { billingSubscriptionQuery } from "../graphql/billing";
import { SubscriptionInfo } from "../components/subscriptioninfo";
import { CheckoutContainer } from "../containers/checkout";

export class SubscriptionContainer extends React.Component {
	render() {
		return (
			<Query
				query={billingSubscriptionQuery}
				fetchPolicy={"network-only"}
			>
				{(result: QueryResult) => {
					if (result.loading)
						return null; // FIXME: Loading spinner.

					if (!result.data ||
						!result.data.billingSubscription ||
						result.data.billingSubscription.status === "Cancelled" ||
						result.data.billingSubscription.status === "RequiresAction") {
						// No subscription, so render the checkout
						return <CheckoutContainer/>;
					} else {
						// Active or subscription that can be re-activated

						return <SubscriptionInfo
							startedAt={new Date(result.data.billingSubscription.startedAt)}
							nextRenewalAt={new Date(result.data.billingSubscription.nextRenewalAt)}
							status={result.data.billingSubscription.status}
							cardBrand={result.data.billingSubscription.cardBrand}
							cardExpiry={result.data.billingSubscription.cardExpiry}
							card4Digits={result.data.billingSubscription.card4Digits}
						/>;
					}
				}}
			</Query>
		);
	}
}