import React from "react";

import "./invoicepreview.scss";
import { Divider } from "./core/divider";

interface InvoiceFacebookAccount {
	_id: string;
	name: string;
	currency: string;
	originalCurrency: number;
	USD: number;
}

interface InvoicePreviewProps {
	subTotal: string;
	tax: string;
	taxPercent: string;
	total: string;
	perAccount: InvoiceFacebookAccount[];
}

interface InvoicePreviewState {
	showDetails: boolean;
}

export class InvoicePreview extends React.Component<InvoicePreviewProps, InvoicePreviewState> {
	constructor(props) {
		super(props);
		this.toggleShowDetails = this.toggleShowDetails.bind(this);
		this.state = {
			showDetails: false,
		};
	}
	
	toggleShowDetails() {
		this.setState({
			showDetails: !this.state.showDetails,
		});
	}
	
	render() {
		return (
			<div className="invoicepreview js-invoicepreview">
				<div className="heading">
					<div className="title">Noora subscription</div>
					<div className="subtitle">Billed monthly</div>
				</div>
				<div className="content">
					<div className="line">
						<div className="label">Sub total</div>
						<div className="amount js-amount-sub">${this.props.subTotal}</div>
					</div>
					<div className="line is-collapsible">
						<a onClick={() => this.toggleShowDetails()}>{this.state.showDetails ? "Hide details" : "Show details"}</a>
					</div>
					{this.state.showDetails ?
							this.props.perAccount.map(account => {
								return (
									<div key={account._id} className="line is-account">
										<div className="label">
											<div>{account.name} - {account.originalCurrency} {account.currency}</div>
											<div className="sublabel">{account._id}</div>
										</div>
										<div className="amount">${account.USD}</div>
									</div>
								);
							})
						: 
							null					
					}
					<div className="line">
						<div className="label js-label-vat">VAT ({this.props.taxPercent}%)</div>
						<div className="amount js-amount-vat">${this.props.tax}</div>
					</div>
					<Divider compact={true}/>
					<div className="line is-total">
						<div className="label">Grand total</div>
						<div className="amount js-amount-total">${this.props.total}</div>
					</div>		
					<div className="line infotext">
						<div>How does our pricing work? <a href="https://www.getnoora.com/pricing" target="_blank">Read more here.</a></div>
					</div>		
				</div>
			</div>
		);
	}
}